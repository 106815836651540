import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const ImagesContainer = ({ image }) => {
  return (
    <div>
      <div className="overflow-x-scroll snap-x flex gap-4 lg:gap-8">
        {image.map((i) => (
          <div className="snap-center  flex-shrink-0">
            <GatsbyImage
              height={463}
              image={i.gatsbyImageData}
              placeholder="tracedSVG"
              alt="image of Nexpa"
              className="block flex-shrink-0"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesContainer;
