import { useStaticQuery, graphql } from "gatsby";

const useCards = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryCard {
        allContentfulCard {
          nodes {
            title
            personas
            slug
            smallDescription
            description {
              description
            }
            imageCard {
              gatsbyImageData(width: 560, height: 382)
            }
            image {
              gatsbyImageData(height: 761)
            }
            imagesContainer {
              gatsbyImageData(height: 463)
            }
            servicios
          }
        }
      }
    `
  );
  return data.allContentfulCard.nodes.map((i) => ({
    title: i.title,
    personas: i.personas,
    slug: i.slug,
    smallDesc: i.smallDescription,
    desc: i.description.description,
    imageCard: i.imageCard.gatsbyImageData,
    image: i.image.gatsbyImageData,
    imagesContainer: i.imagesContainer,
    tags: i.servicios,
  }));
};

export default useCards;
