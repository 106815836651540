import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import useCards from "../utils/useCards";
import { GatsbyImage } from "gatsby-plugin-image";
// import ImagesContainer from "../components/modules/ImageContainer";
import ServicesContainer from "../components/widgets/ServicesContainer";
import { IconPeople } from "../atoms/Icons";
import ImagesContainer from "../components/modules/ImageContainer";

const CardPage = ({ pageContext }) => {
  const data = useCards();

  const { slug } = pageContext;
  const slugString = JSON.stringify(slug);

  const dataFiltered = data.filter(
    (i) => JSON.stringify(i.slug) === slugString
  );

  console.log(dataFiltered);

  return (
    <Layout>
      {dataFiltered.map((i) => (
        <>
          <Seo title={i.title} />
          <div className="pb-8">
            <div className=" grid lg:grid-cols-2 place-items-center mx-auto px-2 gap-8 pb-8 pt-2 lg:px-0">
              <div className="mr-auto hidden lg:block">
                <GatsbyImage
                  height={761}
                  image={i.image}
                  alt="card image"
                  placeholder="tracedSVG"
                  className="mr-auto"
                />
              </div>
              <div className="flex flex-col items-center lg:items-start justify-evenly gap-4 max-w-md lg:max-w-full text-center lg:text-left">
                <p className="paragraph font-bold uppercase">{i.title}</p>
                <p className="paragraph flex items-center">
                  <IconPeople />
                  Capacidad {i.personas} Personas
                </p>
                <p className="paragraph">{i.smallDesc}</p>
                <p className="paragraph">{i.desc}</p>
                <div className="flex gap-4 py-2">
                  <Link to="/contact">
                    <button className="button-primary">Quiero Reservar</button>
                  </Link>
                  <Link to="/card/#cabins">
                    <button className="button-primary">Ver mas Cabañas</button>
                  </Link>
                </div>
                <ServicesContainer data={i.tags} />
              </div>
            </div>
            <ImagesContainer image={i.imagesContainer} />
          </div>
        </>
      ))}
    </Layout>
  );
};

export default CardPage;
